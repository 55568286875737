<template>
  <div class="enter-page">
    <!-- <div class="banner">
        <h1>{{ $i18n.t('soft.label') }}</h1>
    </div> -->
    <div class="banner">
        <div class="banner_titile">
            <span>HOLOWITS Support</span>
        </div>
        <div class="t-nav">
            <div v-for="(item, index) in links" :key="item.name" class="target" @click="toPage1(item.path)" @mouseenter="active(item)" @mouseout="unactive(item)">
                <div class="icon">
                    <img :src="domainUrl + item.icon" alt="" :style="{'display': index !== 2 ? 'block': 'none'}"/>
                    <img :src="domainUrl + item.activeIcon" alt="" :style="{'display': index === 2 ? 'block': 'none'}"/>
                </div>
                <div class="name" :style="{'color': index === 2 ? 'rgb(199, 0, 11)': ''}">{{ item.name }} <span v-if="index === 0" class="beta">New</span></div>
                <div v-if="index === 2" class="line"></div>
            </div>
        </div>
    </div>
    <div class="list">
        <div class="item" v-for="(item, index) in labels" :key="index">
            <div class="title">{{ item }}</div>
            <div class="content">
                <span class="link-item" v-for="(value, i) in list[item]" :key="i"><a @click="toPage(value.softName, value.id)">{{ value.softName }}</a></span>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import {getPage} from '@/api/softwareApi'
export default {
name: 'SoftIndex',
data() {
    return {
        hasRight: false,
        labels: [],
        list: {},
        links: [
                {
                    icon: 'support/申请单.png',
                    activeIcon: ' support/申请单.png',
                    // activeIcon: 'support/wiki_red.png',
                    name: 'Wiki',
                    path: '/holowitswiki',
                    active: false
                },
                {
                    icon: 'support/firmware.png',
                    // activeIcon: 'support/firmware.png',
                    activeIcon: 'support/firmware_red.png',
                    name: 'Firmware',
                    path: '/firmware',
                    active: false
                },
                {
                    icon: 'support/software.png',
                    // activeIcon: 'support/software.png',
                    activeIcon: 'support/software_red.png',
                    name: 'Software',
                    path: '/soft-download',
                    active: false
                },
                {
                    icon: 'support/knowledge.png',
                    // activeIcon: 'support/knowledge.png',
                    activeIcon: 'support/knowledge_red.png',
                    name: 'Knowledge',
                    path: '/knowledge',
                    active: false
                },
                {
                    icon: 'support/e-learning.png',
                    // activeIcon: 'support/e-learning.png',
                    activeIcon: 'support/e-learning_red.png',
                    name: 'E-learning',
                    path: '/training',
                    active: false
                },
                {
                    icon: 'support/policy_4.png',
                    // activeIcon: 'support/policy_4.png',
                    activeIcon: 'support/terms_red.png',
                    name: 'Policy,Terms',
                    path: '/terms',
                    active: false
                },
            ],
    }
},
mounted() {
    this.getData()
},
methods: {
    toPage1(path) {
        if (path.includes('/holowitswiki')) {
            if (!this.$store.state.client.user.id) {
                this.$router.push({path: '/login'})
                return false;
            }
        }
        if (path) {
            this.$router.push({path: path})
        }
    },
    active(item) {
        item.active = true
    },
    unactive(item) {
        item.active = false
    },
    async getData() {
       const {data: {data, isSuccess}} = await getPage()
       if (isSuccess) {
            for (let item of data) {
                if (!this.labels.includes(item.label)) {
                    this.labels.push(item.label)
                    this.list[item.label] = []
                }
                this.list[item.label].push({
                    softName: item.soft_name,
                    id: item.id
                })
            }
        }
    },
    toPage(name, id) {
        this.$store.commit('software/setSofts', {[id]: name});
        const url = this.$router.resolve({
            path: `/soft-download/${id}`
        })
        window.open(url.href,'_blank')
    }
},
filters: {
    dateFilter(value) {
      return value ? value.split(' ')[0] : ''
    }
}
}
</script>

<style lang="scss" scoped>
.enter-page {
    text-align: center;
    background-color: #f5f7fa;
    min-height: inherit;
    // height: 100%;

    // .banner {
    //     height: 320px;
    //     background-image: url('/static/img/banner.8e08f679.jpg');
    //     background-size: 100% auto;
    //     background-position: center;
    //     background-repeat: no-repeat;
    //     display: flex;
    //     justify-content: center;
    //     align-items: center;
    //     position: relative;
    //     &:after {
    //         content: '';
    //         position: absolute;
    //         left: 0;
    //         bottom: 0;
    //         right: 0;
    //         top: 0;
    //         background-color: rgba(0, 0, 0, 0.29);
    //         pointer-events: none;
    //     }
    //     h1 {
    //         text-align: left;
    //         width: 100%;
    //         padding-left: 200px;
    //         font-weight: 600;
    //         color: #ffffff;
    //         z-index: 2;
    //     }
    // }
}
.list {
    margin: auto;
    margin-top: 10px;
    padding: 10px;
    width: 80%;
    background-color: #ffffff;
    .item {
        padding-bottom: 30px;
        .title {
            text-align: left;
            font-size: 18px;
            font-weight: 600;
            padding-bottom: 5px;
            border-bottom: 3px solid rgb(0, 0, 0);
        }
        .content {
            overflow: hidden;
            padding-bottom: 20px;
            .link-item {
                width: 25%;
                float: left;
                text-align: left;
                padding: 10px 0;
                // font-weight: 600;
                border-bottom: 1px solid transparent;
                a {
                    color: #000000;
                    cursor: pointer;
                    &:hover {
                        color: #c7000b;
                        border-bottom: 1px solid #c7000b;
                    }
                }
            }
        }
    }
}
.soft-body {
    margin: auto;
    width: 60%;
    h1 {
        text-align: center;
        margin-bottom: 100px;
    }
    .table-item {
        margin-bottom: 40px;
    }
    .soft-label {
        text-align: left;
        font-size: 28px;
    }
    .soft-item {
        span {
            text-align: left;
            float: left;
            width: 25%;
        }
    }
    .big-label {
        text-align: left;
        font-size: 16px;
        font-weight: 600;
        color: #333333;
        padding-left: 10px;
    }
}

.target {
    min-width: 80px;
    height: 100%;
    cursor: pointer;
    &:hover {
        .icon {
            width: 70px;
            height: 70px;
            img:nth-child(1) {
                display: none !important;
            }
            img:nth-child(2) {
                display: block !important;
            }
        }
        .name {
            color: rgb(199, 0, 11);
        }
    }

    &:nth-child(1) {
        position: relative;
        .icon {
            width: 70px;
            height: 70px;
            img:nth-child(1) {
                display: none !important;
            }
            img:nth-child(2) {
                display: block !important;
            }
        }
        .name {
            color: rgb(199, 0, 11);
            display: flex;
            align-items: center;
            justify-content: space-between;
            .beta {
                margin-left: 5px;
                background-color: rgba(255, 174, 0, 0.774);
                color: #000000;
                font-size: 12px;
                border-radius: 5px;
                padding: 2px 5px;
            }
        }
        .line {
            height: 5px;
            width: 100%;
            background-color: rgb(197, 0, 11);
            position: absolute;
            bottom: 1px;
        }
    }

    .icon {
        transition: all 0.2s;
        width: 60px;
        height: 60px;
        margin: 0 auto;
        img {
            width: 100%;
            height: 100%;
        }
    }
    .name {
        text-align: center;
        font-size: 18px;
    }
}
.target+.target {
    margin-left: 90px;
}

.banner {
  height: 420px;
  background-image: url('https://resources.holowits.com.sg/support/banner_2.png');
  background-size: 100% auto;
  background-position: center;
  background-repeat: no-repeat;
  color: #FFFFFF;
//   padding-top: 120px;
  position: relative;
  
  .banner_titile {
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    span {
        font-size: 42px;
        text-shadow: 2px 2px 9px #00000099;
    }
  }
}
.t-nav {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 120px;
    background-color: #00000075;
    // padding: 10px 0;
    .target {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
}
</style>